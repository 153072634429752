<template>
  <div>
    <b-modal
      id="change-status-to-recycle"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('RecycleStatus')"
      no-close-on-backdrop
      size="lg"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          label-for="input-2"
        >
          <p>
            {{ $t("RecycleMode") }}
          </p>
        </b-form-group>
        <div style="display: flex;gap: 10px;">
          <b-form-group
            style="width: 100%;"
            :label="$t('currentLocation')"
          >
            <vue-select
              v-model="theLocation"
              :placeholder="$t('Select')"
              disabled
            />
          </b-form-group>
          <b-form-group
            style="width: 100%;"
            :label="$t('newLocation')"
          >
            <vue-select
              v-model="form.newLocationId"
              :options="getLocationsMoving"
              label="locationName"
              required
              :placeholder="$t('Select')"
              :state="validateState('newLocationId')"
              :reduce="e => e.locationId"
            />
            <p
              v-if="form.newLocationId == null && isSubmitClicked == true"
              style="color: red"
            >
              {{ $t('This_is_a_required_field') }}
            </p>
          </b-form-group>
        </div>
        <div style="display: flex;gap: 10px;">
          <b-form-group
            style="width: 100%;"
            :label="$t('Team')"
          >
            <vue-select
              v-model="form.teamNameId"
              :options="getTeamsNames"
              label="teamName"
              :reduce="(value) => value.teamNameId"
              :placeholder="$t('Select')"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Notes')"
            style="width: 100%"
          >
            <b-form-input
              v-model="form.notes"
              :placeholder="$t('Type Here')"
            />
          </b-form-group>
        </div>

        <pre />
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="submit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 5px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';

export default {
  components: {},
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['currentLocation', 'articleName'],
  data() {
    return {
      isSubmitClicked: false,
      theLocation: null,
      form: {
        newLocationId: null,
        notes: null,
        teamNameId: null,
      },
    };
  },
  validations: {
    form: {
      newLocationId: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(['getLocationsMoving', 'getTeamsNames']),
  },

  watch: {
    currentLocation(value) {
      this.theLocation = value.locationName
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async submit() {
      this.isSubmitClicked = true
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        const objekti = {
          ...this.form,
          locationId: this.currentLocation.locationId,
          newState: 'Recycle',
          articleNumber: this.articleName,
        }
        this.$emit('recycleMode', objekti);
        this.$refs.modal.hide();
        this.onReset();
      } catch (error) {
        console.log(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.notes = null
      this.isSubmitClicked = false
      this.form.teamNameId = null
      this.form.newLocationId = null
    },
  },
};
</script>

<style lang="scss" scoped>

.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>

<template>
  <b-sidebar
    id="product-track-trace"
    ref="modal"
    header-class="headerModalToggle"
    right
    shadow
    no-close-on-esc
    width="55%"
  >
    <template #title>
      <strong style="color: #101828">{{ $t('ArticleTrackAndTrace') }}</strong>
    </template>
    <template v-slot:header-close>
      <button
        style="color: #98A2B3;height: 60px;"
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div
      class="tt"
      style="border-top: 0px solid lightgray; padding: 16px"
    >
      <table
        class="team_table"
      >
        <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
          <tr>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
            <th>
              {{ $t('State') }}
            </th>
            <th>
              {{ $t('Notes') }}
            </th>
            <th>
              {{ $t('Created') }}
            </th>
            <th>
              {{ $t('ExecutorName') }}
            </th>
            <th>
              {{ $t('ProcessingUser') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(products, index) in getProductInStockTrackTrace"
            :key="index"
          >
            <td>{{ products.articleNumber }}</td>
            <td>{{ $t(products.state) }}</td>
            <td>{{ products.notes }}</td>
            <td>{{ convertUTCDateToLoacalDate(products.created) }}</td>
            <td>{{ products.executorName }}</td>
            <td>{{ products.processingUser }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-pagination
        v-if="getProductInStockTrackTraceTotalItems > 15"
        v-model="pageNumber"
        :total-rows="getProductInStockTrackTraceTotalItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        size="md"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  props: ['productInStockId'],
  data() {
    return {
      pageNumber: 1,
      pageSize: 15,
    }
  },
  computed: {
    ...mapGetters([
      'getProductInStockTrackTrace',
      'getProductInStockTrackTraceTotalItems',
    ]),
  },
  watch: {
    pageSize(value) {
      this.loadProductInStockTrackAndTrace({
        productInStockId: this.productInStockId, pageNumber: this.pageNumber, pageSize: value,
      })
    },
    pageNumber(value) {
      this.loadProductInStockTrackAndTrace({
        productInStockId: this.productInStockId, pageNumber: value, pageSize: this.pageSize,
      })
    },
  },
  methods: {
    ...mapActions([
      'loadProductInStockTrackAndTrace',
    ]),
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 16px 9px;
}
</style>

<template>
  <div
    class="location"
    style="border: 1px solid lightgray; margin-top: 47px"
  >
    <div style="height: 45vh; overflow-y: scroll; border-bottom: 1px solid lightgray;">
      <div style="width: 100%;">
        <table
          class="team_table"
          style="width: 100%; box-shadow: none; font-size: 13px;cursor:auto"
        >
          <thead>
            <tr>
              <th>
                {{ $t('Location') }}
              </th>
              <th>
                {{ $t('Amount') }}
              </th>
              <th>
                {{ $t('AddToOrder') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="getTotalStockGroupByLocation.length > 0 && articleName">
            <tr
              v-for="(article, index) in getTotalStockGroupByLocation"
              :key="index"
            >
              <td>{{ $t(article.locationName) }}</td>
              <td>{{ $t(article.totalAmountInStock) }}</td>
              <td @click="orderStateLocation(article)">
                <button
                  v-b-modal.change-status-to-recycle
                  class="button"
                >
                  {{ $t('Recycle') }}
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                colspan="5"
              >
                {{ $t('NoLocationFound') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="height: 57vh; overflow-y: scroll; margin-top: 10px">
      <button
        v-if="articleName"
        style="margin: 1% 0%; width: 25%"
        class="button"
        @click="showArticlesInPending"
      >
        {{ $t('ShowArticlesInPending') }}
      </button>
      <span
        v-if="showInPending"
        style="margin-left: 10px;"
      >{{ $t('PendingTransferredArticles') }}</span>
      <table
        v-if="showInPending && articleName"
        class="team_table"
        style="width: 100%; box-shadow: none; font-size: 13px;cursor: auto"
      >
        <thead>
          <tr>
            <th>
              {{ $t('State') }}
            </th>
            <th>
              {{ $t('Location') }}
            </th>
            <th>
              {{ $t('ReceiverLocation') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getItemsInPendingOrTransit.length > 0">
          <tr
            v-for="(items, index) in getItemsInPendingOrTransit"
            :key="index"
          >
            <td><p :class="stateClass(items.currentState)">
              {{ $t(items.currentState) }}
            </p></td>
            <td>{{ items.senderLocationName }}</td>
            <td>{{ items.receiverLocationName }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="2">
              {{ $t('NoArticle') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ChangeStatusToInStock
      :article-name="articleName"
      :current-location="currentLocation"
      @recycleMode="changeStateToStock"
    />
  </div></template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import ChangeStatusToInStock from './modals/ChangeStatusToInStock.vue'

export default {
  components: {
    ChangeStatusToInStock,
  },
  props: ['articleName'],
  data() {
    return {
      location: null,
      locationId: null,
      stateOfRecycle: null,
      productInStockIdRecycle: null,
      pageSize: 15,
      currentLocation: null,
      showInPending: false,
    }
  },
  computed: {
    ...mapGetters(['getItemsInPendingOrTransit', 'getLocationsMoving', 'getTotalStockGroupByLocation', 'getTotalStockGroupByLocation']),
  },
  watch: {
    articleName(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showInPending = false;
      }
    },
  },
  mounted() {
    this.$on('resetShowPending', this.resetShowPending);
  },
  methods: {
    ...mapActions(['getPendingOrTransitItems', 'totalStockGroupedByLocation', 'teamNames', 'productInStock_location_Pagination', 'updateRecycleStock', 'movingLocations']),
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    stateClass(value) {
      return {
        green: value === 'Received',
        red: value === 'Pending',
        orange: value === 'InTransit',
      }
    },
    showArticlesInPending() {
      this.showInPending = true;
      this.getPendingOrTransitItems({ articleNumber: this.articleName })
    },
    resetShowPending() {
      this.showInPending = false;
    },
    orderStateLocation(value) {
      this.currentLocation = value
      this.movingLocations()
      this.teamNames()
    },
    changeStateToStock(obj) {
      this.updateRecycleStock({
        object: obj,
        successCallback: () => {
          this.totalStockGroupedByLocation(
            {
              articleNumber: this.articleName,
            },
          )
        },
      })
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 10px 9px !important;
}
.location {
  width: 50%;
}
.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: orange;
}
@media screen and (max-width: 1200px) {
  .location {
    width: 100%;
  }
}
</style>
